import { Dropdown } from 'src/design-system'
import * as React from 'react'
import * as MegaModal from '../../mega-modal'
import { store } from 'store/index'
import { Skill } from 'store/modules/skills'
import { observer } from 'mobx-react-lite'
import { successToast } from 'app/packs/src/utils/success-toast'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { trackEvent } from 'app/packs/src/services/event-tracker'

export const EditableByInfoLine: React.VFC<{
  skill: Skill
  editable: boolean
}> = (props) => {
  const { skill, editable } = props

  const currentUser = store.currentUser
  if (!currentUser?.org) return null

  const isOrgAdmin = currentUser.isAdminOfOrg(currentUser.org.id)
  const editorOfTeamSkillUsedIn = skill.teams.some((team) =>
    team.hasEditor(currentUser.id)
  )
  if (!isOrgAdmin && !editorOfTeamSkillUsedIn) return null

  return (
    <MegaModal.InfoLine
      label="Editable by"
      value={
        editable ? (
          <EditableLevel skill={skill} isOrgAdmin={isOrgAdmin} />
        ) : (
          <>{skillEditableByLevelDescriptions(skill.editableBy)}</>
        )
      }
      labelProps={{
        'data-element-id': 'editable-by-label',
      }}
    />
  )
}

type EditableLevelProps = {
  skill: Skill
  isOrgAdmin: boolean
}

const editableDescriptionLevels: Array<Record<string, string>> = [
  {
    value: 'admin_editors',
    name: 'Admins and Editors',
  },
  {
    value: 'admin_only',
    name: 'Admins only',
  },
]

export const skillEditableByLevelDescriptions = (level: string) => {
  return editableDescriptionLevels.find((l) => l.value === level)?.name
}

const EditableLevel = observer((props: EditableLevelProps) => {
  const { skill, isOrgAdmin } = props

  const [open, setOpen] = React.useState(false)

  const name = skillEditableByLevelDescriptions(skill.editableBy)

  const updateEditableBy = async (level: Record<string, string>) => {
    setOpen(false)

    const result = await store.skills.update(skill.id, {
      editableBy: level.value,
    })

    if (result.success) {
      successToast(`Changes saved, this skill is now editable by ${level.name}`)

      trackEvent('$track_skill_editable_by_updated', {
        updatedTo: level.value,
        skillId: skill.id,
      })

      if (skill.defaultVariant) {
        store.changes.fetchForResource(
          skill.defaultVariant.id,
          'SkillVariant',
          1
        )
      }
    } else {
      errorToast(
        'Sorry, there was a problem saving your changes. Please try again'
      )
    }
  }

  if (!isOrgAdmin) {
    return <div className="flex gap-2 items-center text-gray-900">{name}</div>
  }

  return (
    <Dropdown.Root open={open} onOpenChange={setOpen}>
      <Dropdown.Trigger className="flex w-[180px] justify-between">
        {name}
      </Dropdown.Trigger>
      <Dropdown.Content>
        {editableDescriptionLevels.map((level) => (
          <Dropdown.Button
            type="button"
            key={level.value}
            className={
              'w-[180px] bg-transparent focus:!bg-gray-50 hover:!bg-gray-50 flex flex-row gap-x-2 px-4 py-3 items-center cursor-pointer text-gray-900'
            }
            onClick={() => updateEditableBy(level)}
          >
            {level.name}
          </Dropdown.Button>
        ))}
      </Dropdown.Content>
    </Dropdown.Root>
  )
})
