import { RequestResult } from '../../api/types'
import { Skill } from 'store/modules/skills'
import { SkillVariant } from 'store/modules/skill-variants'
import { store } from 'store/index'

export class SkillVariantDialogVm {
  constructor(
    private skill: Skill,
    private skillVariant?: SkillVariant,
    private source?: string
  ) {}

  get createMode() {
    return !this.skillVariant || this.skillVariant.default
  }

  get submitButtonText() {
    return this.createMode ? 'Create variant' : 'Save'
  }

  get title() {
    return this.createMode ? 'Create new variant ' : 'Rename variant'
  }

  get toastMessage() {
    return this.createMode ? 'Skill variant created' : 'Skill variant updated'
  }

  dispatchEvents() {
    document.dispatchEvent(new Event('refreshOrgSkills'))
    document.dispatchEvent(new Event('refreshSkillsDrawerSkills'))
    document.dispatchEvent(new Event('refreshTeamSkillsPage'))
  }

  async onSubmit(name: string) {
    let result: RequestResult

    if (this.skillVariant && !this.skillVariant.default) {
      result = await store.skillVariants.update(
        this.skillVariant.id,
        { name },
        { source: this.source }
      )
    } else {
      result = await store.skillVariants.create(
        { name, skill: this.skill.id },
        { source: this.source }
      )
    }

    return result.success
      ? { success: true, data: result.data }
      : { success: false, errors: result.errors }
  }

  async assignVariantToFramework(frameworkId: string, variantId: string) {
    await store.frameworksSkills.create(
      { framework: frameworkId, skillVariant: variantId },
      { source: this.source, include: ['skill'] }
    )
  }
}
