import {
  Button,
  GlobalModal,
  Modal,
  Tag,
  useModalContext,
  useModalProps,
} from 'src/design-system'
import { Check, CopySimple, GitBranch, X } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Skill } from 'store/modules/skills'
import { SkillVariantDialog } from 'components/skill-variant-dialog'

export type SkillCopyDialogProps = {
  onOpenChange?: (open: boolean) => void
  onMakeACopy: () => void
  skill: Skill
  source?: string
}

export const SKILL_COPY_DIALOG_ID = 'skill-copy-dialog'

export const SkillCopyDialog = observer(() => {
  const { onOpenChange, onMakeACopy, skill, source } =
    useModalProps<SkillCopyDialogProps>(SKILL_COPY_DIALOG_ID)

  const [submitting, setSubmitting] = React.useState(false)
  const { closeModal } = useModalContext()

  const onCopy = React.useCallback(() => {
    setSubmitting(true)
    onMakeACopy()
    setSubmitting(false)
    closeModal()
  }, [onMakeACopy, closeModal])

  if (!skill) return null
  return (
    <GlobalModal.Root
      id={SKILL_COPY_DIALOG_ID}
      className="w-[604px]"
      onOpenChange={(open) => {
        onOpenChange?.(open)
      }}
      title={skill.name}
    >
      <div className="flex flex-col gap-8 w-full justify-between p-8">
        <div className="flex flex-row items-end gap-x-2 font-bold text-xl">
          <div>Are you sure you want to make a copy of {skill.name}?</div>
          <Modal.CloseButton
            asChild
            className="p-2 -mr-2 ml-auto flex self-start items-center rounded-sm !outline-none border-px border-solid border-transparent focus:!border-theme-60"
          >
            <button className="focus:ring-0" title="Close modal">
              <X aria-hidden className="h-4 w-4" />
            </button>
          </Modal.CloseButton>
        </div>
        <div className="flex flex-row gap-2 justify-center">
          <div className="flex flex-col gap-2 border border-gray-100 rounded p-3 w-full">
            <div className="flex justify-between">
              <GitBranch className="h-6 w-6 text-gray-600" weight="bold" />
              <Tag>Recommended</Tag>
            </div>
            <div className="font-bold text-base mt-1">Create a variant</div>
            <ul className="list-none p-0 mb-1 flex flex-col  gap-y-2 text-gray-600 text-sm">
              <li className="flex ps-0 gap-x-2.5">
                <Check
                  aria-hidden
                  className="w-4 h-4 mt-0.5 flex-shrink-0 text-green-600"
                  weight="bold"
                />
                Linked to original skill
              </li>
              <li className="flex ps-0 gap-x-2.5">
                <Check
                  aria-hidden
                  className="w-4 h-4 mt-0.5 flex-shrink-0 text-green-600"
                  weight="bold"
                />
                Variants and original skill can be compared when comparing
                positions
              </li>
              <li className="flex ps-0 gap-x-2.5">
                <X
                  aria-hidden
                  className="w-4 h-4 mt-0.5 flex-shrink-0 text-gray-600"
                  weight="bold"
                />
                Only skill levels and examples can be edited
              </li>
            </ul>
            <SkillVariantDialog skillId={skill.id} source={source}>
              <Button className="max-w-none mt-auto">Create a variant</Button>
            </SkillVariantDialog>
          </div>
          <div className="flex flex-col gap-2 border border-gray-100 rounded p-3 w-full">
            <CopySimple className="h-6 w-6 text-gray-600" weight="bold" />
            <div className="font-bold text-base mt-1">Make a copy</div>
            <ul className="list-none p-0 mb-1 flex flex-col gap-y-2 text-gray-600 text-sm">
              <li className="flex gap-x-2.5">
                <X
                  aria-hidden
                  className="w-4 h-4 mt-0.5 flex-shrink-0 text-gray-600"
                  weight="bold"
                />
                Not linked to original skill
              </li>
              <li className="flex gap-x-2.5">
                <X
                  aria-hidden
                  className="w-4 h-4 mt-0.5  flex-shrink-0 text-gray-600"
                  weight="bold"
                />
                Will be shown as separate skill in compare
              </li>
              <li className="flex gap-x-2.5">
                <Check
                  aria-hidden
                  className="w-4 h-4 mt-0.5  flex-shrink-0 text-green-600"
                  weight="bold"
                />
                Whole skill can be edited
              </li>
            </ul>
            <Button
              disabled={submitting}
              variant="outline"
              className="max-w-none mt-auto"
              onClick={onCopy}
            >
              Make a copy
            </Button>
          </div>
        </div>
        <div className="flex justify-center">
          <Button
            as="a"
            variant="outline"
            target="_blank"
            href="https://help.progressionapp.com/en/articles/8065002-how-to-variant-skills"
          >
            Learn more about variants
          </Button>
        </div>
      </div>
    </GlobalModal.Root>
  )
})
