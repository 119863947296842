import {
  Button,
  Input,
  InputGroup,
  Link,
  Modal,
  useModalContext,
} from 'src/design-system'
import {
  ChatDots,
  Info,
  LinkSimple,
  TagSimple,
  TextAlignLeft,
  UsersFour,
  X,
} from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { errorToast } from '../../utils/error-toast'
import { SKILL_MODAL_ID } from 'components/skill-modal/utils'
import { SkillVariantDialogVm } from './skill-variant-dialog-vm'
import { successToast } from '../../utils/success-toast'
import { store } from 'store/index'
import { LoadingContent } from 'components/loading-content'

export type SkillVariantDialogProps = {
  children?: React.ReactElement
  defaultOpen?: boolean
  frameworkId?: string
  onOpenChange?: (open: boolean) => void
  skillId: string
  skillVariantId?: string
  source?: string
}

export const SkillVariantDialog = observer<SkillVariantDialogProps>((props) => {
  const {
    children,
    defaultOpen,
    frameworkId,
    onOpenChange,
    skillId,
    skillVariantId,
    source,
  } = props

  const { openModal } = useModalContext()
  const skill = store.skills.byId(skillId)
  const skillVariant = store.skillVariants.byId(skillVariantId)

  const vm = React.useMemo(() => {
    if (!skill) return
    return new SkillVariantDialogVm(skill, skillVariant, source)
  }, [skill, skillVariant, source])

  const [invalidName, setInvalidName] = React.useState(false)
  const [open, setOpen] = React.useState(defaultOpen)
  const [submitting, setSubmitting] = React.useState(false)
  const [variantName, setVariantName] = React.useState('')

  const onSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      if (!vm || !skill) return
      e.preventDefault()

      setSubmitting(true)
      setInvalidName(false)

      const result = await vm.onSubmit(variantName)

      if (
        result.errors &&
        result.errors.some(
          (error: { detail: string }) =>
            error.detail === 'Name has already been taken'
        )
      ) {
        setInvalidName(true)
      } else if (!result.success) {
        errorToast('Something went wrong')
      }

      if (result.success) {
        const skillVariantId = Object.values(
          result.data?.skillVariants || {}
        )[0]?.id

        if (frameworkId && skillVariantId) {
          await vm.assignVariantToFramework(frameworkId, skillVariantId)
        }

        onOpenChange?.(false)
        setOpen(false)
        if (!skillVariant) setVariantName('')
        successToast(vm.toastMessage)
        vm.dispatchEvents()
        openModal(SKILL_MODAL_ID, { skillId: skill.id, skillVariantId })
      }

      setSubmitting(false)
    },
    [frameworkId, onOpenChange, openModal, skill, skillVariant, variantName, vm]
  )

  React.useEffect(() => setOpen(defaultOpen), [defaultOpen])
  React.useEffect(() => {
    if (open && !skill) store.skills.fetchOne(skillId)
  }, [open, skill, skillId])

  React.useEffect(() => {
    if (skillVariant && !skillVariant.default) setVariantName(skillVariant.name)
  }, [skillVariant, skillVariant?.name])

  if (!skill || !vm) return null

  return (
    <Modal.Root
      className="w-[680px]"
      onOpenChange={(open) => {
        onOpenChange?.(open)
        setOpen(open)
      }}
      open={open}
      title={skill.name}
      trigger={children}
    >
      <LoadingContent loading={!skill}>
        <form
          className="flex flex-col w-full justify-between px-6 pb-6 mt-4"
          onSubmit={onSubmit}
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-row items-end gap-x-2 font-bold text-xl">
              <div>
                {vm.title}
                {!skillVariant && (
                  <span className="font-normal text-gray-600 text-xs">
                    for {skill.name}
                  </span>
                )}
              </div>
              <Modal.CloseButton
                asChild
                className="p-2 -mr-2 ml-auto flex items-center rounded-sm !outline-none border-px border-solid border-transparent focus:!border-theme-60"
              >
                <button className="focus:ring-0" title="Close modal">
                  <X aria-hidden className="h-4 w-4" />
                </button>
              </Modal.CloseButton>
            </div>
            <div className="">
              <InputGroup.Root className="w-full">
                <div className="flex flex-row gap-3">
                  <Input
                    aria-label="Name"
                    autoComplete="off"
                    autoFocus
                    className="w-full"
                    isInvalid={invalidName}
                    name="name"
                    onChange={(e) => {
                      setInvalidName(false)
                      setVariantName(e.target.value)
                    }}
                    placeholder="Variant name"
                    required
                    value={variantName}
                  />
                  <Button
                    className="ml-auto items-center px-3"
                    disabled={submitting}
                    type="submit"
                  >
                    {vm.submitButtonText}
                  </Button>
                </div>
                {invalidName && (
                  <InputGroup.ErrorMessage>
                    Variant name already exists
                  </InputGroup.ErrorMessage>
                )}
              </InputGroup.Root>
            </div>
            {frameworkId && skill.frameworkIds.includes(frameworkId) && (
              <div className="flex flex-row text-sm gap-2 items-center text-gray-600">
                <Info aria-hidden className="w-4 h-4" weight="bold" />
                This Framework will automatically be switched to using this
                variant
              </div>
            )}
          </div>
        </form>
        <div className="bg-gray-50 pb-3">
          <div className="font-bold text-base mx-8 pt-8 mb-5">
            How variants work
          </div>
          <ul className="list-none p-0 mx-8 flex flex-col gap-y-2 text-gray-600 text-sm">
            <li className="flex ps-0 flex-row items-center gap-x-2.5">
              <LinkSimple
                aria-hidden
                className="w-4 h-4 flex-shrink-0 flex"
                weight="bold"
              />
              A variant will always be linked to a “main” skill variant
            </li>
            <li className="flex flex-row items-center gap-x-2.5">
              <TextAlignLeft
                aria-hidden
                className="w-4 h-4 flex-shrink-0 flex"
                weight="bold"
              />
              Only skill level descriptions and examples can be changed for
              skill variants
            </li>
            <li className="flex flex-row items-center gap-x-2.5">
              <UsersFour
                aria-hidden
                className="w-4 h-4 flex-shrink-0 flex"
                weight="bold"
              />
              Multiple teams can use the variant
            </li>
            <li className="flex flex-row items-center gap-x-2.5">
              <TagSimple
                aria-hidden
                className="w-4 h-4 flex-shrink-0 flex"
                weight="bold"
              />
              The skill will be labelled as “Skill name (variant name)”
            </li>
          </ul>
        </div>
        <div className="rounded-b-lg bg-orange-100 text-orange-600 py-3 px-8 flex flex-row justify-center items-baseline gap-x-3">
          <div>
            <ChatDots
              aria-hidden
              className="w-4 h-4 flex-shrink-0"
              weight="bold"
            />
          </div>
          <p className="m-0 text-orange-700 text-sm">
            Variants are new, and we’d love to hear what you think —{' '}
            <Link
              className="text-orange-700 p-0"
              href="https://airtable.com/shrMZXwkilntRE9BA"
              isExternal
            >
              tell us how we can improve
            </Link>{' '}
            🙌
          </p>
        </div>
      </LoadingContent>
    </Modal.Root>
  )
})
